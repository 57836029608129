<template>


<div class="container">
    <modal-job-review v-model="selectedJob" inputId="worker-jobs-modal" />

    <pre>{{allJobsStatus}}</pre>
    <div class="accordion" id="jobsAccordion">
        <div v-for="job in allJobsSorted" :key="job.id" class="accordion-item mb-2">
            <div class="list-group">
                <button class="accordion-button collapsed align-left text-dark" :class="{'bg-warning': job.dateReviewed == null}" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-job-' + job.id" aria-expanded="false" :aria-controls="'collapse-job-' + job.id">
                    <div class="row">
                        <div class="col-md-8">
                            <span v-for="(name, index) in fullNameList(job.workPosition)" :key="index">
                                <span :class="{'badge':true, 'bg-primary':index==0, 'bg-secondary':index!=0 }">
                                    {{name}}
                                </span>
                            </span>
                            <h3><span class="badge bg-primary">{{getPositionFullindex(job.workPosition)}}</span> {{job.workPosition.position_unit.name}}</h3>
                            <h6>Započeto: {{formatDateTime(job.dateStart)}} </h6>
                            <h6>Recenzirano: {{formatDateTime(job.dateReviewed)}}</h6>
                            <h6>Završeno: {{formatDateTime(job.dateFinished)}}</h6>
                        </div>
                        <div class="col-md-4">
                            <h3 class="mb-0">Dodeljeno:</h3>
                            <h6 v-for="employee in job.employees" :key="employee.id" class="mb-0">{{employee.firstName}} {{employee.lastName}}</h6>
                        </div>
                    </div>
                </button>


                <div :id="'collapse-job-' + job.id" class="accordion-collapse collapse" aria-labelledby="headingOne">
                    <a v-for="work in job.works" :key="work.id" class="list-group-item list-group-item-action" :class="{'bg-approved': isApproved(work), 'bg-rejected': isRejected(work), 'bg-not-started': isNotStarted(work)}" aria-current="true">
                        <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                            <span class="badge bg-info">{{getLocationFullindex(work.workLocation)}}</span>
                            <span class="ms-1" v-for="(name, index) in fullNameList(work.workLocation)" :key="index">
                                <span :class="{'badge':true, 'bg-secondary':true }">
                                    {{name}}
                                </span>
                                ❯
                            </span>
                            {{work.workLocation.name}}
                            <span v-if="isWorkInProgress(work)">
                                <img src="@/assets/gear-loading.gif" width="30px" height="30px" alt="...">
                            </span>
                        </h5><br>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import EmployeesService from '@/service/EmployeesService';

import {mapState} from 'vuex'
import UtilService from '@/service/UtilService.js';
import ModalJobReview from '../modals/ModalJobReview.vue';

export default {

    name: "EmployeeJobs",

    components: { ModalJobReview },

    data() {
        return {
            user: {},
            id: null,
            selectedJob: null,
        }
    },

    computed: {
        ...mapState([
            'allJobs',
            'worksInJobStatus',
            'allJobsStatus',
        ]),

        allJobsSorted(){
            if (!this.allJobs) return [];

            let jobs = UtilService.deepClone(this.allJobs);
            jobs.sort((a,b) => (a.dateStart > b.dateStart ? 1 : -1 ));
            jobs.forEach(job => {
                if (!job.works) return 0;
                job.works.sort((a,b) => (a.id < b.id ? 1 : -1 ));
            });
            return jobs;
        }
    },

    created() {
        this.loadUserAndJobs();
    },

    methods: {
        formatDate : UtilService.formatDate,
        formatTime : UtilService.formatTime,
        formatDateTime : UtilService.formatDateTime,

        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,

        loadUserAndJobs() {
            EmployeesService.getOneEmploeeByUsername(this.$route.params.username)
            .then((response) => { 
                this.user = response.data;
                this.id = response.data.id; 
                this.loadJobs();
            });
        },

        loadJobs() {
            this.$store.dispatch('loadJobsForEmployee', this.id);
        },

        openJobsModal(job) {
            this.$store.dispatch('resetJobStatus');
            this.selectedJob = UtilService.deepClone(job); 
        },

        isChecked(work){
            return work.workStatus == 'DONE'
        },

        isNotStarted(work){
            return work.workStatus == 'NOT_DONE'
        },
        
        isRejected(work){
            return work.workStatus == 'REJECTED'
        },
        
        isApproved(work){
            return work.workStatus == 'APPROVED'
        },
        
        isJobReviewed(job) {
            let not_finished;
            let _this = this;
            not_finished = job.works?.some(function(work) {
                return !(_this.isWorkReviewed(work));

            });
            return !not_finished;
                
        },

        isJobReady(job){
            return job.dateFinished != null;
        },

        sendReview(job) {
            this.$store.dispatch('submitReview', {jobAssignmentId: job.id});
        },

        isWorkReviewed(work){
            if (!work) return false;
            return  work.workStatus == "APPROVED" ||
                    work.workStatus == "REJECTED" ||
                    work.workStatus == "NOT_DONE";
        },

        isWorkInProgress(work){
            if (!work) return false;
            return  work.workStatus == "READY";
        },

    },
}
</script>

<style scoped>
    .bg-checked {
        background: rgb(189 255 189);
    }
    
    .bg-not-started {
        background: rgb(230 230 230);
    }

    .bg-approved {
        background: rgb(150 255 150);
    }

    .bg-rejected {
        background: rgb(255 150 150);
    }

    .bg-reviewed {
        background: #3bfca2;
    }

</style>